import React from "react";
import { Helmet } from "react-helmet";
import { COLORS } from "./../css-vars";

const LoginPage = () => {

  return (
    <div className="group">
      <Helmet>
        <title>Unsupported Browser | ServiceLink</title>
        <meta name="robots" content="noindex, nofollow" />

        <script type="text/javascript">{`
          var ua = window.navigator.userAgent.toString().toLowerCase();

          if (ua.indexOf("trident/6") === -1 && ua.indexOf("msie ") === -1) {
            window.location = "/";
          }
        `}</script>

        <style type="text/css">{`
          * {
            box-sizing: border-box;
          }

          @font-face {
            src: url("/fonts/sol-head-medium.woff") format("woff");
            font-family: sole-headline;
            font-style: normal;
            font-weight: 500;
          }

          body,
          html {
            margin: 0;
            padding: 0;
          }

          body {
            background: ${COLORS.light};
            color: ${COLORS.dark};
            font-family: sans-serif;
          }

          .group {
            margin: 0 auto;
            max-width: 90%;
            width: 520px;
          }

          .logo {
            height: 49px;
            width: 202px;
            margin: 50px 0 0;
          }

          h1 {
            font-family: circular, sans-serif;
            margin: 50px auto 0;
          }

          p {
            line-height: 1.45;
            margin: 20px 0 0;
          }

          p + p {
            margin-top: 20px;
          }

          p a {
            color: ${COLORS.primaryA11y};
            text-decoration: underline;
          }

          p a:hover,
          p a:focus {
            color: ${COLORS.dark};
          }

          .button {
            color: ${COLORS.dark};
            font-weight: 600;
            text-decoration: none;
          }

          .button:focus,
          .button:hover {
            text-decoration: underline;
          }
        `}</style>
      </Helmet>

      <img className="logo" src="/logo@2x.png" alt="ServiceLink logo" />
      <h1>Unsupported Browser</h1>
      <p>A more up-to-date browser is required to access the ServiceLink website. We recommend switching to using the latest version of <a href="https://www.google.com/chrome/" title="Download Google Chrome">Google Chrome</a>, <a href="https://www.mozilla.org/firefox/new/" title="Download Firefox">Firefox</a> or&nbsp;<a href="https://www.microsoft.com/edge" title="Download Microsoft Edge">Microsoft Edge</a>.</p>
      <p>You can still contact us on <a className="button" href="tel:+1800-777-8759" title="Call ServiceLink">(800) 777-8759</a>.</p>

    </div>
  );
};

export default LoginPage;
